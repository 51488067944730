const theme = {
  deepSea: "#063044",
  neptune: "#8bc0ba",
  grape: "#26163b",
  deco: "#c4ce64",
  kenyanCopper: "#600002",
  granySmith: "#d4df67",
  rawSienna: "#d6685b",
  manatee: "#9a9b9f",
  frenchGrey: "#ceced0",
  white: "#fff",
  grey: "#797e83",
  paraGrey: "#696969;",
  greyLight: "#eee",
  black: "#000",
  strongred: "#f00",
  maxWidth: "1000px",
  baseLineHeight: "1.5",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
  fontPrim: "Open Sans",
  fontSec: "Libre Baskerville",
  fontTer: "Josefin Sans",
  fontAwesome: "FontAwesome",
}

export default theme
